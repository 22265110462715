import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import ApiService from "@/core/services/api.service";
import ShopApiService from "@/core/services/api.service.shop-api";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History
} from 'tiptap-vuetify'
export default {
  props: {
    modal: {
      default: false
    },
    value: Boolean
  },

  name: "legal",
  data() {
    return {
      valid: false,
      requiredRule: [v => !!v || "Field is required"],
      isSaving: false,
      languages: ["MNE", "ENG", "GER"],
      legalId: null,
      alertMessage: "",
      alert: false,
      shopServiceApiInitiated: false,
      legal: {
        id: null,
        titleFirstSection: null,
        contentFirstSection: null,
        titleSecondSection: null,
        contentSecondSection: null,
        language: null,
        titleBusinessNote: null,
        contentBusinessNote: null
      },

      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [Heading, {
          options: {
            levels: [1, 2, 3]
          }
        }],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak
      ],
    };
  },
  components: {TiptapVuetify},
  async created() {
    await ShopApiService.init();
    this.shopServiceApiInitiated = true;
    this.legalId = this.$route.params.legalId;
    let title =
      this.legalId != null ? "Legal details" : "Insert new legal";
    this.$store.dispatch(SET_BREADCRUMB, [{ title: title }]);
    if (this.legalId != null) {
      ApiService.get(`api/legal`, `${this.legalId}`)
        .then(response => {
          this.$log.debug("Legal: ", response.data);
          this.legal = response.data;
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    }
  },
  methods: {
    previousState() {
      this.$router.go(-1);
    },
    validateAndSave() {
      if (this.$refs.form.validate()) {
        this.saveLegal(this.legal);
      }
    },
    saveLegal(legal) {
      this.loading = true;

      if (legal.id != null) {
        ApiService.put(`/api/legal`, legal)
          .then(response => {
            this.$log.debug("Legal updated: " + response);
            this.alert = false;
          })
          .catch(error => {
            this.$log.error("Error: ", error);
            this.alertMessage = error.response.data.message;
            this.alert = true;
            this.errored = true;
          })
          .finally(() => {
            this.loading = false;
            this.isSaving = false;
            if (this.alert === false) {
              this.previousState();
            }
          });
      } else {
        ShopApiService.post(`/shop-legal`, legal)
          .then(response => {
            this.$log.debug("Legal created: " + response);
            this.alert = false;
          })
          .catch(error => {
            this.$log.error("Error: ", error);
            this.alertMessage = error.response.data.message;
            this.alert = true;
            this.errored = true;
          })
          .finally(() => {
            this.loading = false;
            this.isSaving = false;
            if (this.alert === false) {
              this.previousState();
            }
          });
      }
    }
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    ...mapGetters(["currentUser"]),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.clearFields();
          this.$emit("input", value);
        }
      }
    }
  }
};
